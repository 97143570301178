/* eslint-disable */
<template>
  <div>
    <!-- <div class="container">
      <div class="col-lg-6 offset-lg-3"> -->
        <!-- <div v-if="ready">
          <p v-for="user in info">
            {{ user.username }} {{ user.type }}
          </p>
        </div>

        <div v-if="!ready">
          <h4>Enter your username</h4>
          <form @submit.prevent="addUser">
            <div class="form-group row">
              <input
                v-model="username"
                type="text"
                class="form-control col-9"
                placeholder="Enter username here"
              >
              <input
                type="submit"
                value="Join"
                class="btn btn-sm btn-info ml-1"
              >
            </div>
          </form>
        </div>
        <h2 v-else>
          {{ username }}
        </h2>
        <div
          v-if="ready"
          class="card bg-info"
        >
          <div class="card-header text-white">
            <h4><span class="float-right">{{ connections }} connections</span></h4>
          </div>
          <ul class="list-group list-group-flush text-right">
            <small
              v-if="typing"
              class="text-white"
            >{{ typing }} is typing</small>
            <li
              v-for="message in messages"
              class="list-group-item"
            >
              <span :class="{'float-left':message.type === 1}">
                {{ message.message }}
                <small>:{{ message.user }}</small>
              </span>
            </li>
          </ul>

          <div class="card-body">
            <form @submit.prevent="send">
              <div class="form-group">
                <input
                  v-model="newMessage"
                  type="text"
                  class="form-control"
                  placeholder="Enter message here"
                >
              </div>
            </form>
          </div>
        </div>
      </div>
    </div> -->
    <v-card class="mb-6">
      <v-card-title>Websocket to receive Data</v-card-title>
      <v-card-text>
        <div class="col-md-12 my-3">
          <h2>Room</h2>
          <input v-model="roomId">
        </div>
      </v-card-text>
      <v-row>
        <v-card-text>
          <v-card>
            <vue-webrtc
              ref="webrtc"
              width="100%"
              :room-id="roomId"
              socket-u-r-l="https://pharmaguide.ngrok.io"
              @joined-room="logEvent"
              @left-room="logEvent"
              @opened-room="logEvent"
              @share-started="logEvent"
              @share-stopped="logEvent"
              @error="onError"
            />
          </v-card>
        </v-card-text>
        <v-col class="col-md-12 my-3">
          <v-btn
            type="button"
            class="btn btn-primary"
            @click="onJoin"
          >
            Join
          </v-btn>
          <v-btn
            type="button"
            class="btn btn-primary"
            @click="onLeave"
          >
            Leave
          </v-btn>
          <v-btn
            type="button"
            class="btn btn-primary"
            @click="onCapture"
          >
            Caputre Photo
          </v-btn>
          <v-btn
            type="button"
            class="btn btn-primary"
            @click="onShareScreen"
          >
            Share Screen
          </v-btn>
        </v-col>
        <v-col>
          <img
            :src="img"
            class="img-responsive"
          />
        </v-col>
      </v-row>
    </v-card>
    <v-card>
      <VueSignaturePad
        ref="signaturePad"
        width="500px"
        height="500px"
        :options="{ onBegin, onEnd }"
      />
      <div>
        <button @click="save">
          Save
        </button>
        <button @click="undo">
          Undo
        </button>
      </div>
    </v-card>
    <v-tabs v-model="currentTab">
      <v-tab>Pt</v-tab>
      <v-tab>EKG</v-tab>
      <v-tab>LVm,s</v-tab>
      <v-tab>LVd+Atria</v-tab>
      <v-tab>RV</v-tab>
      <v-tab>Ao</v-tab>
      <v-tab>AoV</v-tab>
      <v-tab>MV</v-tab>
      <v-tab>TV-PV</v-tab>
      <v-tab>Peric-Pleura</v-tab>
      <v-tab>Sum</v-tab>
      <v-tab>Unlab</v-tab>
      <v-tab>Ctrl</v-tab>
    </v-tabs>

    <v-tabs-items v-model="currentTab">
      <v-tab-item
        v-for="item in items"
        :key="item.id"
      >
        <v-card-title>
          Tab Title: {{ item.title }} Tab ID#:{{ item.id }}
        </v-card-title>
        <v-card-text flat>
          <FormulateForm
            v-model="formValues"
            :schema="item.schema"
          />
        </v-card-text>
      </v-tab-item>
    </v-tabs-items>
    <v-row class="row justify-center align-center">
      <v-card-actions>
        <v-col>
          <div class="demo-space-x">
            <v-btn
              color="success"
              dark
              @click="generatePdf"
            >
              Download PDF
              <v-icon
                dark
                right
              >
                {{ icons.mdiCloudDownloadOutline }}
              </v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-card-actions>
    </v-row>
  </div>
</template>

<script>
import { mdiCloudDownloadOutline } from '@mdi/js'
import { jsPDF } from 'jspdf'
import { WebRTC } from 'vue-webrtc'
import Vue from 'vue'

// import { io } from 'socket.io-client'

// // https://www.npmjs.com/package/vue-signature-pad/v/2.0.4
// const socket = io('localhost:3001')

Vue.component(WebRTC.name, WebRTC)

/* eslint-disable */
export default {
  name: 'Portal',
  data() {
    return {
      newMessage: null,
      messages: [],
      typing: false,
      username: null,
      ready: false,
      info: [],
      connections: 0,

      img: null,
      roomId: '',
      icons: {
        mdiCloudDownloadOutline,
      },
      formValues: {
        cheese: 0,
      },
      currentTab: 0,
      items: [
        {
          title: 'Pt',
          id: 1,
          schema: [
            {
              component: 'h3',
              class: 'text-2xl mb-4',
              children: 'Pt',
            },
            {
              type: 'select',
              label: 'Pizza size',
              name: 'size',
              placeholder: 'Select a size',
              options: {
                small: 'Small',
                large: 'Large',
                extra_large: 'Extra Large',
              },
              validation: 'required',
            },
            {
              component: 'div',
              class: 'flex',
              children: [
                {
                  name: 'cheese',
                  label: 'LV Mass & Wall Thickness',
                  type: 'checkbox',
                  validation: 'min:1,length',
                  options: {
                    'Normal LV mass, size & wall Thickness': 'Normal LV mass, size & wall Thickness',
                    'Normal LV mass': 'Normal LV mass',
                    'Proximal Septal thickening': 'Proximal Septal thickening',
                    'Proximal Septal thickenting (mild)': 'Proximal Septal thickenting (mild)',
                  },
                  'outer-class': ['w-1/2'],
                },
                {
                  name: 'toppings',
                  label: 'EF ASE 2015',
                  type: 'checkbox',
                  validation: 'min:2,length',
                  options: {
                    salami: 'ASE 2015(male)',
                    prosciutto: 'ASE 2015(female)',
                    avocado: 'mild 41-45%',
                    onion: 'mild to moderate ~40%',
                  },
                  'outer-class': ['w-1/2'],
                },
              ],
            },
            {
              component: 'div',
              class: 'flex',
              children: [
                {
                  type: 'select',
                  name: 'country_code',
                  label: 'Code',
                  value: '1',
                  'outer-class': ['w-1/4 mr-4'],
                  options: {
                    1: '+1',
                    49: '+49',
                    55: '+55',
                  },
                },
                {
                  type: 'text',
                  label: 'Phone number',
                  name: 'phone',
                  inputmode: 'numeric',
                  pattern: '[0-9]*',
                  validation: 'matches:/^[0-9-]+$/',
                  'outer-class': ['flex-grow'],
                  'validation-messages': {
                    matches: 'Phone number should only include numbers and dashes.',
                  },
                },
                {
                  label: 'Slider example?',
                  type: 'range',
                  name: 'range',
                  min: '0',
                  max: '100',
                  value: '45',
                  validation: 'required|min:10|max:90',
                  'error-behavior': 'live',
                },
              ],
            },
            {
              type: 'submit',
              label: 'Order pizza',
            },
          ],
        },
      ],
    }
  },
  // created() {
  //   window.onbeforeunload = () => {
  //     socket.emit('leave', this.username)
  //   }

  //   socket.on('chat-message', data => {
  //     this.messages.push({
  //       message: data.message,
  //       type: 1,
  //       user: data.user,
  //     })
  //   })

  //   socket.on('typing', data => {
  //     this.typing = data
  //   })

  //   socket.on('stopTyping', () => {
  //     this.typing = false
  //   })

  //   socket.on('joined', data => {
  //     this.info.push({
  //       username: data,
  //       type: 'joined',
  //     })

  //     setTimeout(() => {
  //       this.info = []
  //     }, 5000)
  //   })

  //   socket.on('leave', data => {
  //     this.info.push({
  //       username: data,
  //       type: 'left',
  //     })

  //     setTimeout(() => {
  //       this.info = []
  //     }, 5000)
  //   })

  //   socket.on('connections', data => {
  //     this.connections = data
  //   })
  // },
  computed: {},
  // watch: {
  //   newMessage(value) {
  //     value ? socket.emit('typing', this.username) : socket.emit('stopTyping')
  //   },
  // },
  beforeMount() {
    this.roomId = this.$route.params.roomId
  },
  methods: {
    // send() {
    //   this.messages.push({
    //     message: this.newMessage,
    //     type: 0,
    //     user: 'Me',
    //   })

    //   socket.emit('chat-message', {
    //     message: this.newMessage,
    //     user: this.username,
    //   })
    //   this.newMessage = null
    // },

    // addUser() {
    //   this.ready = true
    //   socket.emit('joined', this.username)
    // },
    onBegin() {
      console.log('=== Begin ===')
    },
    onEnd() {
      console.log('=== End ===')
    },
    undo() {
      this.$refs.signaturePad.undoSignature()
    },
    save() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature()
      console.log(isEmpty)
      console.log(data)
    },
    generatePdf() {
      const doc = new jsPDF()
      const formValues = this.formValues
      doc.addImage('phox.png', 'PNG', 15, 60, 60, 88)
      doc.text('You selected a pizza thats size: ' + formValues.size, 10, 10)
      for (var i = 0; i <= 3; i++) {
        doc.text('You selected the following options ' + formValues.cheese[i], 10, 20 + i * 10)
      }
      doc.save('a4.pdf')
    },
    onCapture() {
      this.img = this.$refs.webrtc.capture()
    },
    onJoin() {
      this.$refs.webrtc.join()
    },
    onLeave() {
      this.$refs.webrtc.leave()
    },
    onShareScreen() {
      this.img = this.$refs.webrtc.shareScreen()
    },
    onError(error, stream) {
      console.log('On Error Event', error, stream)
    },
    logEvent(event) {
      console.log('Event : ', event)
    },
  },
}
</script>
